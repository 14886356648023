<template>
    <div class="task-breakdown-table">
      <h2>Task Breakdown</h2>
      <table>
        <thead>
          <tr class="bold-row">
            <th>Task Name</th>
            <th>Site</th>
            <th>Department</th>
            <th>Total Task Count</th>
            <th>Total Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item,index in taskTable" :key="item.task_name + item.department_name + item.site_name" :class="{'bold-row':index == taskTable.length-1}">
            <td>{{ item.task_name }}</td>
            <td>{{ item.department_name }}</td>
            <td>{{ item.site_name }}</td>
            <td>{{ item.total_task_count }}</td>
            <td>{{ item.total_minute_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      taskTable: {
        type: Array,
        required: true,
      },
    },
 
  };
  </script>
  
  <style scoped>
  .task-breakdown-table {
    margin: 20px;
    border-collapse: collapse;
    width: 100%;
  }
  
  .task-breakdown-table h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .task-breakdown-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .task-breakdown-table th,
  .task-breakdown-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .task-breakdown-table th {
    background-color: #f2f2f2;
    color: black;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .task-breakdown-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .task-breakdown-table tr:hover {
    background-color: #ddd;
  }

  .bold-row {
  font-weight: bold;
}
  </style>